@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground: rgb(0, 0, 0);
    --foreground-secondary: rgb(52, 52, 52);

    --background-start: rgb(255, 255, 255);
    --background-end: rgb(77, 120, 141);
    --background: rgb(255, 255, 255);
    --action: rgb(65, 188, 254); 
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground: rgb(255, 255, 255);
        --foreground-secondary: rgb(171, 171, 171);

        --background-start: rgb(0, 0, 0);
        --background-end: rgb(77, 120, 141);
        --background: rgb(77, 120, 141);
        --action: rgb(164, 217, 246);
    }
}

body {
    color: var(--foreground);
    background: linear-gradient(to bottom, transparent, var(--background-end))
        var(--background-start);
    /*noinspection CssInvalidPropertyValue*/
    min-height: -webkit-fill-available;
}

@layer utilities {
    .text-balance {
        /*noinspection CssInvalidPropertyValue*/
        text-wrap: balance;
    }
}

h1 {
    @apply font-bold text-xl;
}

h2 {
    @apply font-semibold text-lg;
}

.text-area {
    background-color: var(--background);
    @apply rounded-[5em] px-12 lg:px-24 py-12 my-8;
}

.modal-area {
    background-color: var(--background);
    @apply rounded-[5em] px-6 py-6;
}

.result-area {
    background-color: var(--background);
    @apply rounded-[5em] px-12 py-6 my-2 w-full;
}

.candidate {
    background-color: var(--background);
    @apply rounded-[3em] p-8;
}

.details-btn {
    
    @apply transition duration-[300ms] hover:drop-shadow-xl active:drop-shadow-none basis-0 hover:opacity-80;
}

.answer-btn {
    background-color: var(--background);
    
    @apply transition duration-[300ms] rounded-full hover:drop-shadow-xl py-3 active:drop-shadow-none grow basis-0;
}

.answer-btn:hover   {
    box-shadow: inset 0 0 0 4px var(--foreground);

}

.answer-btn-chosen, .answer-btn-chosen:hover, .answer-btn:active {
    box-shadow: inset 0 0 0 5px var(--foreground);
}

.answer-btn-next {
    background-color: var(--foreground);
    color: var(--background-start);
    @apply transition duration-[300ms] rounded-full hover:drop-shadow-xl py-3 active:drop-shadow-none basis-0;
}

.answer-btn-next:hover {
    background-color: var(--background-start);
    color: var(--foreground);
}

.hidden {
    @apply opacity-0
}

.breathe {
    animation: breathe 3s infinite alternate ease-in-out;
}

.levitate {
    animation: levitate 3s infinite alternate ease-in-out;
}

@keyframes breathe {
    from {
        background-color: var(--background);
        color: var(--foreground)
    }

    to {
        background-color: var(--foreground);
        color: var(--background-start)

    }
}

@keyframes levitate {
    from {
        transform: translateY(0);
        /* @apply drop-shadow-sm; */

    }
    to {
        transform: translateY(-10px);
        @apply drop-shadow-2xl;

    }
}

.inline-react-icon {
    @apply inline align-text-top mt-[2px];
}

.main-container {
    @apply flex min-h-screen flex-col items-center justify-between pb-safe-or-10 lg:pb-0
}


.bg-end {
    background-color: var(--background-end);
}